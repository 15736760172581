import React from "react";
import { DiscographyIndexPageProps } from "../lib/";
import PageLayout from "./PageLayout";

export default ({ discography }: DiscographyIndexPageProps) => (
  <PageLayout>
    <article>
      <h1>Discography</h1>
      <ul>
        {discography.map(({ title, artwork, date, slug }) => (
          <li aria-label="Album" key={slug}>
            <a href={slug}>
              <h2>
                <span aria-label="Title">{title}</span> (
                <span aria-label="Date">{date}</span>)
              </h2>
              <div style={{ width: 200 }}>{artwork}</div>
            </a>
          </li>
        ))}
      </ul>
    </article>
  </PageLayout>
);
